import '@hotwired/turbo-rails'
import 'arrive'
import * as bootstrap from 'bootstrap'
import imagesLoaded from 'imagesloaded'

// stimulus support
import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
const application = Application.start()
const controllers = import.meta.glob('~/**/*_controller.js', { eager: true })
registerControllers(application, controllers)

// scroll to anchor on load when needed
function scrollToAnchor() {
  if (window.location.hash) {
    var el = document.querySelector(window.location.hash)
    if (el) { el.scrollIntoView(true) }
  }
}

// sharer.js
import 'sharer.js'
function initSharer() { Sharer.init() }

// plausible.io + Turbo
function trackVisit() {
  try { plausible('pageview') }
  catch(err) { console.log('Plausible: not available.') }
}

// make all external links open in new tab
document.arrive('.richtext', { existing: true }, function(el) {
  const selector1 = "a[href^='http']:not([href^='http://www.jack-russell-consulting.de']):not([href^='http://jack-russell-consulting.de']):not([href^='https://www.jack-russell-consulting.de']):not([href^='https://jack-russell-consulting.de'])"
  const selector2 = "a[href^='mailto']"
  el.querySelectorAll(selector1).forEach((e) => { e.setAttribute("target", "_blank") })
  el.querySelectorAll(selector2).forEach((e) => { e.setAttribute("target", "_blank") })
})

// logo carousel
document.arrive('#client-carousel', { existing: true }, function(el) {
  imagesLoaded(el, () => {
    const carousel = new bootstrap.Carousel(el, {
      interval: 1750,
      ride: 'carousel',
      pause: false
    })
  })
})

// home animation
document.arrive('body.home', { existing: true }, function(el) {
  imagesLoaded(el, function() {
    setTimeout( function() { el.classList.remove('opaque') }, 250)
    setTimeout( function() { el.querySelector('.content h1').classList.add('show') }, 1500)
    setTimeout( function() {
      el.querySelector('.content h2').classList.add('show')
      // el.querySelector('.content .aesc-logo').classList.add('show')
    }, 3000)
    setTimeout( function() { el.querySelector('.col-side').classList.add('expanded') }, 4000)
  })
})

// menu icon
document.arrive('.menu-open-icon', { existing: true }, function (el) {
  el.addEventListener('click', () => { document.querySelector('.col-side').classList.remove('closed') })
})
document.arrive('.menu-close-icon', { existing: true }, function (el) {
  el.addEventListener('click', () => { document.querySelector('.col-side').classList.add('closed') })
})

// window size hack
function initWindowSize() {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

// on-load handlers
document.addEventListener('turbo:load', () => {
  trackVisit()
  initSharer()
  scrollToAnchor()
  initWindowSize()
})

window.addEventListener('popstate', scrollToAnchor)
window.addEventListener('resize', initWindowSize)

// CSS Styles
import '~/stylesheets/website.scss'
